<template>
  <div>
    <v-chip
        :class="{
        'danger':TICKET_STATUS.MUST_REPLY(status),
        'success':TICKET_STATUS.REPLIED(status),
        'cancel':TICKET_STATUS.CLOSED(status)}"
    >
      <div v-if="TICKET_STATUS.MUST_REPLY(status)">
        {{ TICKET_STATUS_TEXT.MUST_REPLY }}
      </div>
      <div v-if="TICKET_STATUS.REPLIED(status)">
        {{ TICKET_STATUS_TEXT.REPLIED }}
      </div>
      <div v-if="TICKET_STATUS.CLOSED(status)">
        {{ TICKET_STATUS_TEXT.CLOSED }}
      </div>
    </v-chip>
  </div>
</template>

<script>
import {TICKET_STATUS} from "@/view/pages/chat-with-students/Widget/Shared/STATUS";
import {TICKET_STATUS_TEXT} from "@/view/pages/chat-with-students/Widget/Shared/STATUS";

export default {
  name: "StatusBadge",
  data: function () {
    return {
      TICKET_STATUS,
      TICKET_STATUS_TEXT
    }
  },
  props: {
    status: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss">
.success {
  background-color: #4caf50 !important;
  color: white !important;
}

.danger {
  background: rgba(255, 7, 7, 0.84) !important;
  color: white !important;
}

.cancel {
  background: rgba(141, 137, 137, 0.28) !important;
  color: black !important;
}
</style>
