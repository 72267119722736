export const TICKET_STATUS = {
    MUST_REPLY: (status) => {
        return status == 1 || status == 3
    },
    REPLIED: (status) => {
        return status == 2 || status == 4
    },
    CLOSED: (status) => {
        return status == 5
    }
}

export const SENDER_TYPE = {
    STUDENT: 1,
    TEACHER: 2
}

export const TICKET_STATUS_TEXT = {
    MUST_REPLY: 'Chưa trả lời',
    REPLIED: 'Đã trả lời',
    CLOSED: 'Đã đóng'
}

export const errorTypeLabel = (errorType) => {
    switch (errorType) {
        case 1:
            return "Lỗi trong bài chấm";
        case 2:
            return "Tư vấn học thuật";
        case 3:
            return "Khác";
    }
}
