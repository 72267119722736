<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Danh sách câu hỏi trong bài chấm</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">

    </div>
    <div>

      <v-row style="padding:20px">
        <v-col cols="3" style="padding:0px" class="text-center tab-heading">
          <div class="tab-item" @click="changeStatus('')" :class="{'active':filter.status==''}">
            Tất cả
          </div>
        </v-col>

        <v-col cols="3" style="padding:0px" class="text-center tab-heading">
          <div class="tab-item" @click="changeStatus('1,3')" :class="{'active':filter.status=='1,3'}">
            Cần phản hổi
          </div>
        </v-col>

        <v-col cols="3" style="padding:0px" class="text-center tab-heading">
          <div class="tab-item" @click="changeStatus('2,4')" :class="{'active':filter.status=='2,4'}">
            Đã phản hồi
          </div>
        </v-col>

        <v-col cols="3" style="padding:0px" class="text-center tab-heading">
          <div class="tab-item" @click="changeStatus('5')" :class="{'active':filter.status=='5'}">
            Đã đóng
          </div>
        </v-col>
      </v-row>
      <br>
      <br>
      <v-row class="p-2 mt-4 mb-0">
        <v-col cols="2">
          <v-autocomplete
              v-if="canAccess('marking-ticket-management')"
              v-model="filter.marker_id"
              :items="markers"
              item-text="name"
              item-value="id"
              label="Markers"
          ></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-autocomplete
              v-model="filter.course_id"
              label="Courses"
              :items="courses"
              item-text="name"
              item-value="id"
          ></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-autocomplete
              v-model="filter.class_id"
              label="Classes"
              :items="classes"
              item-text="name"
              item-value="id"
          ></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-text-field
              label="Student" hint="Search Student(Name,Phone,Email)" v-model="filter.student"></v-text-field>
        </v-col>

        <v-col cols="4" style="display: flex; position: relative;
        top:-20px;
        justify-content: center;
        align-items: center">
          <v-radio-group
              row
              v-model="filter.late">
            <template v-slot:label>
              <div>Trạng thái deadline ?</div>
            </template>
            <v-radio
                label="Tất cả"
                :value="0"
            ></v-radio>
            <v-radio
                label="Trả lời muộn"
                :value="1"
            ></v-radio>
          </v-radio-group>
          <v-btn
              color="red"
              class="ma-2 font-weight-bold"
              outlined
              @click="clearFilter()"
          >
            <v-icon left>mdi-backup-restore</v-icon>
            Hủy lọc
          </v-btn>
        </v-col>

      </v-row>

      <v-row class="mt-0 mb-0">
        <v-col cols="12" class="d-flex align-items-center pb-0 pt-0">
          <div class="ml-2">
            <label class="text-body-1 font-weight-medium"
            >Khoảng thời gian :</label
            >
          </div>
          <div class="d-flex ml-2">
            <label class="text-body-1 font-weight-medium mb-6 pt-4"
            >Từ:</label
            >
            <date-picker
                style=""
                class="ml-2 mt-3"
                v-model="filter.fromDate"
                value-type="format"
                format="YYYY-MM-DD"
            />
          </div>
          <v-icon class="ml-2 mr-2 mb-3">mdi-arrow-right</v-icon>
          <div class="d-flex">
            <label class="text-body-1 font-weight-medium mb-6 pt-4"
            >Đến:</label
            >
            <date-picker
                style=""
                class="ml-2 mt-3"
                v-model="filter.toDate"
                value-type="format"
                format="YYYY-MM-DD"
            />
          </div>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <div class="float-right mr-4">
            <v-btn
                color="#00BFA5"
                class="font-weight-bold"
                outlined large
                @click="exportListQuestion()"
            >
              <v-icon left>mdi-export-variant</v-icon> Export
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="table-responsive">
            <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
            >
              <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Bài chấm</th>
                <th scope="col">Trạng thái</th>
                <!-- <th scope="col">Address</th> -->
                <th scope="col">Thông tin học viên</th>
                <!-- <th scope="col">Total Course</th> -->
                <th scope="col">Lesson/Test</th>
                <th scope="col">Ngày phản hồi</th>
                <th scope="col">Deadline</th>
                <th scope="col">Câu hỏi/ Phản hồi</th>
                <th scope="col">Giáo viên tiếp nhận</th>
                <th scope="col">Action</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(ticketItem, ticketIndex) in list.items">
                <tr :key="ticketIndex">
                  <th scope="row" style="vertical-align: middle">
                    {{ ticketIndex + 1 }}
                  </th>
                  <td>
                    <a style="color:dodgerblue;font-weight: bold"
                       @click="goToMarkingTicketDetail(ticketItem.marking_ticket_id,ticketItem.id)">
                      #{{ ticketItem.marking_ticket_id }}
                    </a>
                  </td>
                  <td>
                    <StatusBadge :status="ticketItem.status"></StatusBadge>
                  </td>
                  <td>
                    <div>
                      <strong>
                        {{ ticketItem.student_name }}
                      </strong>
                    </div>
                    <div>
                      {{ ticketItem.student_phone }}
                    </div>
                    <div>
                      {{ ticketItem.student_email }}
                    </div>
                  </td>
                  <td>
                    {{ ticketItem.course_name }}/
                    {{ ticketItem.test_title }}
                  </td>
                  <td>
                    <p v-if="ticketItem.messages.length > 0">
                      {{ ticketItem.messages[ticketItem.messages.length - 1].created_at | formatDate }}
                    </p>
                    <p v-else class="text-center">
                      -
                    </p>
                  </td>
                  <td>
                    <p style="color:red" v-if="ticketItem.marker_response_deadline">
                      {{ ticketItem.marker_response_deadline | formatDeadlineTime }}
                    </p>
                  </td>
                  <td>
                    <strong v-if="ticketItem.question.length>100">Q:&nbsp;{{
                        ticketItem.question.substr(0, 100) + '...'
                      }}</strong>
                    <strong v-else>Q:&nbsp;{{ ticketItem.question }}</strong>

                    <p v-if="ticketItem.messages.length > 0" style="margin-top:5px">
                      <strong>Last Message:&nbsp;</strong>
                      <span v-if="ticketItem.messages[ticketItem.messages.length-1].message.length>100">{{
                          ticketItem.messages[ticketItem.messages.length - 1].message.substr(0, 100) + '...'
                        }}</span>
                      <span v-else>{{
                          ticketItem.messages[ticketItem.messages.length - 1].message
                        }}</span>
                    </p>

                  </td>
                  <td>
                    <div>{{ ticketItem.pic.name }}</div>
                    <div>{{ ticketItem.pic.email }}</div>
                    <div>{{ ticketItem.pic.phone }}</div>
                  </td>
                  <td>
                    <v-btn
                        color="primary"
                        class="ma-2 font-weight-bold"
                        outlined
                        @click="goToMarkingTicketDetail(ticketItem.marking_ticket_id,ticketItem.id)"
                    >
                      <v-icon left>mdi-eye</v-icon>
                      <div v-if="TICKET_STATUS.MUST_REPLY(ticketItem.status)">Phản hồi học viên</div>
                      <div v-if="TICKET_STATUS.REPLIED(ticketItem.status) || TICKET_STATUS.CLOSED(ticketItem.status)">
                        Xem chi tiết
                      </div>
                    </v-btn>
                    <v-btn
                        v-if="canAccess('marking-ticket-management')"
                        color="danger"
                        class="ma-2 font-weight-bold"
                        outlined
                        @click="assignMarker(ticketItem.marking_ticket_id,ticketItem.id)"
                    >
                      <v-icon>
                        mdi-forward
                      </v-icon>
                      Chuyển tiếp
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template v-if="!list.items.length">
                <tr>
                  <td style="text-align: center" colspan="7">No data</td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </v-col>

        <v-col cols="6">
          <v-pagination
              v-if="list.items.length>0"
              class="mt-5"
              color="#00BCD4"
              style="float: left"
              v-model="list.current_page"
              :length="list.total_page"
              :total-visible="6"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>


    <v-dialog
        v-model="dialog"
        persistent
        width="1024"
    >

      <v-card>
        <v-card-title>
          <span class="text-h5">Chuyển trao đổi cho giáo viên khác</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-autocomplete
                    :items="filterData.markers"
                    item-text="name"
                    item-value="id"
                    label="Chọn giáo viên"
                    v-model="selectTransferMarker"
                ></v-autocomplete>
              </v-col>

              <v-col
                  cols="12"
              >
                <v-textarea label="Lý do" v-model="reasonTransfer"></v-textarea>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
              color="primary"
              variant="text"
              :disabled="!selectTransferMarker || !reasonTransfer"
              @click="doAssignMarker"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-app>
</template>

<script>
//import MainBox from "@/view/pages/chat-with-students/Widget/MainChatBox.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import StatusBadge from "@/view/pages/chat-with-students/Widget/Shared/StatusBadge.vue";
import Swal from "sweetalert2";
import {TICKET_STATUS} from "@/view/pages/chat-with-students/Widget/Shared/STATUS";
import data_to_excel from "../../../mixins/data_to_excel";

export default {
  name: "ChatWithStudent.vue",
  mixins: [data_to_excel],
  data: function () {
    return {
      is_call_api: false,
      list: [],
      filterData: null,
      selectMarker: {},
      selectCourse: {},
      selectClass: {},
      filter: {
        marker_id: "",
        course_id: "",
        class_id: "",
        student: "",
        status: "1,3",
        page: 1,
        late: 0,
        fromDate: "",
        toDate: "",
      },
      queryString: "",
      firstInit: true,
      dialog: false,
      reasonTransfer: "",
      selectTransferMarker: "",
      selectTransferMarkingTicket: "",
      selectTransferQuestion: ""
    }
  },
  computed: {
    TICKET_STATUS() {
      return TICKET_STATUS
    },
    markers() {
      return this?.filterData?.markers ?? [];
    },
    courses() {
      return this?.filterData?.courses ?? [];
    },
    classes() {
      return this?.filterData?.classes ?? [];
    },
  },
  watch: {
    filter: {
      handler: function () {
        if (!this.firstInit) {
          this.filter.marker_id = this.filter.marker_id.id ?? this.filter.marker_id;
          this.filter.course_id = this.filter.course_id.id ?? this.filter.course_id;
          this.filter.class_id = this.filter.class_id.id ?? this.filter.class_id;
          this.filter.late = this.filter.late ? 1 : 0;
          //this.filter.fromDate = this.getStartDateOfMonth();
          //this.filter.toDate = this.getCurrentDateOfMonth();
          this.$router.replace({
            path: this.$route.path,
            query: this.filter
          });
        }
        // else {
        //   this.filter.fromDate = this.getStartDateOfMonth();
        //   this.filter.toDate = this.getCurrentDateOfMonth();
        // }
      },
      deep: true
    },
    'list.current_page': function (page) {
      this.filter.page = page;
    },
    '$route.query': {
      immediate: true,
      async handler(route) {
        const cloneFilter = Object.assign({}, this.filter);
        if (route.marker_id) {
          cloneFilter.marker_id = route.marker_id;
        }
        if (route.course_id) {
          cloneFilter.course_id = route.course_id;
        }
        if (route.class_id) {
          cloneFilter.class_id = route.class_id;
        }
        if (route.student) {
          cloneFilter.student = route.student;
        }
        if (route.status) {
          cloneFilter.status = route.status;
        }
        if (route.page) {
          cloneFilter.page = route.page ?? 1;
        }
        if (route.late) {
          cloneFilter.late = route.late == 1 ? true : false;
        }

        if (this.firstInit) {
          this.filter = Object.assign({}, cloneFilter);
        }

        if (!cloneFilter.late) {
          delete cloneFilter.late;
        }

        if (route.fromDate) {
          cloneFilter.fromDate = route.fromDate;
        }
        if (route.toDate) {
          cloneFilter.toDate = route.toDate;
        }

        this.queryString = Object.keys(cloneFilter)
            .filter(key => cloneFilter[key] != null)
            .map(key => key + '=' + cloneFilter[key])
            .join('&');
        this.getListQuestion();
      }
    }
  },
  components: {
    StatusBadge,
    Loading: () => import("vue-loading-overlay"),
  },
  async mounted() {
    await this.getListFilter();
    this.filter.marker_id = this.filterData.markers.find(item => item.id == this.filter.marker_id) ?? "";
    this.filter.course_id = this.filterData.courses.find(item => item.id == this.filter.course_id) ?? "";
    this.filter.class_id = this.filterData.classes.find(item => item.id == this.filter.class_id) ?? "";
    this.filter.fromDate = this.$route.query.fromDate ? this.$route.query.fromDate : this.getStartDateOfMonth();
    this.filter.toDate = this.$route.query.toDate ? this.$route.query.toDate : this.getCurrentDateOfMonth();
    this.firstInit = false;
    /* this.queryString = Object.keys(this.filter).map(key => key + '=' + this.filter[key]).join('&');
     this.getListQuestion();*/
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    getListQuestion() {
      ApiService.get("prep-app/marking-ticket/discussion/list-question?" + this.queryString).then((response) => {
            this.list = response.data;
          }
      );
    },
    async getListFilter() {
      await ApiService.get("prep-app/marking-ticket/discussion/list-question/filter").then((response) => {
        this.filterData = response.data;
      });
    },
    goToMarkingTicketDetail(ticketId, questionId) {
      this.$router.push({
        name: "MarkingTicketAnswer",
        params: {id: ticketId},
        query: {question_id: questionId, open_from: "discussion_list"}
      });
    },
    changeStatus(status) {
      this.filter.status = status;
    },
    clearFilter() {
      this.filter = {
        marker_id: "",
        course_id: "",
        class_id: "",
        student: "",
        status: "1,3",
        page: 1,
        late: false
      };
    },
    assignMarker(markingTicketId, questionId) {
      this.selectTransferMarkingTicket = markingTicketId;
      this.selectTransferQuestion = questionId;
      this.dialog = true;
    },
    doAssignMarker() {
      ApiService.put(`prep-app/marking-ticket/discussion/${this.selectTransferMarkingTicket}/question/${this.selectTransferQuestion}/transfer`, {
        new_marker_id: this.selectTransferMarker,
        reason: this.reasonTransfer,
      }).then(() => {
        this.dialog = false;
        this.getListQuestion();
        Swal.fire({
          icon: 'success',
          title: 'Thành công',
          text: 'Chuyển trao đổi thành công',
        });
      }).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Lỗi',
          text: 'Chuyển trao đổi thất bại',
        });
      })
    },
    getStartDateOfMonth() {
      let date = new Date();
      return new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substr(0, 10);
    },
    getCurrentDateOfMonth() {
      return new Date().toISOString().substr(0, 10);
    },
    exportListQuestion() {
      let vm = this;
      Swal.fire({
        title: "Bạn có chắc chắn với thao tác này ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Có!",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.get("prep-app/marking-ticket/discussion/data-export-question?" + this.queryString)
              .then(function (res) {
                if (res.status === 200) {
                  vm.is_call_api = false;
                  vm.$toasted.success("Export thành công!!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
                  vm.dataToExcel(
                      'discussionQuestion_'+vm.filter.fromDate+'-'+vm.filter.toDate,
                      {
                        "id": "id",
                        "marking_ticket_id": "marking_ticket_id",
                        "status": "status",
                        "student_name": "student_name",
                        "student_phone": "student_phone",
                        "student_email": "student_email",
                        "marker_name": "marker_name",
                        "marker_email": "marker_email",
                        "marker_phone": "marker_phone",
                        "course": "course",
                        "unit": "unit",
                        "response_timestamp": "response_timestamp",
                        "deadline": "deadline",
                        "question": "question",
                        "last_message": "last_message"
                      },
                      [...res.data]
                  );
                }
              })
              .catch(function (error) {
                vm.is_call_api = false;
                console.log(error);
              });

        }
      });
    },
  },
  filters: {
    formatDeadlineTime: function (time) {
      const newTime = time.split('-');
      return `${newTime[2]}-${newTime[1]}-${newTime[0]}`;
    },
  }
}
</script>

<style>
.tab-item {
  border-bottom: 1px solid #cccccc;
  padding: 20px 0px;
  cursor: pointer;
}

.tab-item.active {
  border-color: blue;
  color: blue;
}

.tab-heading {
  padding: 0px 0px;
}
</style>
